import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useMemo, useContext } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import CommonContext from 'features/context/commonContext';
import dayjs from 'dayjs';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { getBankOptionsWithoutMobilePayment  } from 'utils/bank';
import AutoClientBank from 'features/withdrawals/AutoClientBank';
import Decimal from 'decimal.js';

import {
  FormikSelect,
  FormikTextField,
  FormikUsername,
} from 'features/formikControl';

const CASL_SUBJECT = 'withdrawals';
const SERVICE_NAME = 'withdrawals';

function CreationForm(props) {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { companySettings, companySettingsReady } = useContext(CommonContext);
  const { bankLookup } = useContext(CommonContext);
  const isDataReady = useMemo(() => companySettingsReady, [companySettingsReady]);

  const minWithdrawal = useMemo(() => {
    const minWithdrawalDecimal = new Decimal(companySettings[0]?.transaction?.minWithdrawal?.$numberDecimal || 30);
    return minWithdrawalDecimal.toNumber();
  }, [companySettings]);

  const bankOptions = useMemo(
    () => {
      return getBankOptionsWithoutMobilePayment(bankLookup);
    }, [bankLookup]
  );

  const inputSchema = Yup.object().shape({
    username: Yup.string().required(t('Required')),
    amount: Yup.number().required('Required').min(minWithdrawal, `>= ${minWithdrawal}`).max(100000, '< 100000'),
    remark: Yup.string(),
    clientBank: Yup.object().shape({
      bankName: Yup.string().required(t('Required')),
      accountName: Yup.string().required(t('Required')),
      accountNumber: Yup.string().required(t('Required')).matches(/^[0-9]+$/, { message: t('Only numeric characters are allowed') }),
    }),
  });

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      {
        !isDataReady && <LinearProgress />
      }
      <DialogTitle>{t('Create Withdrawal')}</DialogTitle>
      <Formik
        initialValues={{
          username: '',
          amount: '',
          remark: `Manual withdrawal at ${dayjs().format('YYYY-MM-DD HH:mm:ssZ')}\n`,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={inputSchema}
        onSubmit={async (values) => {
          try {
            await feathers.service(SERVICE_NAME).create(values);
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <AutoClientBank />
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikUsername
                      fullWidth
                      id='username'
                      name='username'
                      label={t('Username')}
                      value={values.username}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='amount'
                      name='amount'
                      label={t('Amount')}
                      value={values.amount}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='remark'
                      multiline
                      rows={2}
                      name='remark'
                      label={t('Remark')}
                      value={values?.remark || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      id='clientBank.bankName'
                      name='clientBank.bankName'
                      label={t('Bank Name')}
                      value={values?.clientBank?.bankName || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        bankOptions.map((bank) => {
                          return (
                            <MenuItem key={bank.key} value={bank.key}>
                              {t(bank.label)}
                            </MenuItem>
                          );
                        })
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='clientBank.accountName'
                      name='clientBank.accountName'
                      label={t('Account Name')}
                      value={values?.clientBank?.accountName || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='clientBank.accountNumber'
                      name='clientBank.accountNumber'
                      label={t('Account Number')}
                      value={values?.clientBank?.accountNumber || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel}>{t('Cancel')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Submit')} onClick={handleSubmit}>{t('Submit')}</LoadingButton>
              </DialogActions>
            </>)
        }
      </Formik>
    </Dialog>
  );
}

export default CreationForm;