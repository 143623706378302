import { useEffect, useState, useMemo } from "react";
import { useFormikContext } from 'formik';
import { useAuth } from 'hooks/useAuth';
import feathers from 'services/feathers';
import { throttle } from 'lodash';

function AutoClientBank(props) {
  const { user } = useAuth();
  const { values, setFieldValue } = useFormikContext();
  const [clientBank, setClientBank] = useState(null);

  const companyId = useMemo(() => user.companyId, [user]);

  const username = values.username;

  useEffect(() => {
    const throttleGetClientBank = throttle(async () => {
      const walletService = feathers.service('wallets');
      const wallets = await walletService.find({
        query: {
          companyId,
          username,
        }
      });

      if (!wallets.data.length) {
        setClientBank(null);
        return;
      }

      setClientBank(wallets.data[0].savedBank);
    }, 1000);

    throttleGetClientBank();
  }, [companyId, username]);

  useEffect(() => {
    setFieldValue('clientBank', clientBank);
  }, [clientBank, setFieldValue]);

  return null;
}

export default AutoClientBank;