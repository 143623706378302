import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import RefreshIcon from '@mui/icons-material/RefreshTwoTone';
import ResetIcon from '@mui/icons-material/LockResetTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import Decimal from 'decimal.js';
import AliasDialog from 'features/aliasDialog/AliasDialog';
import { lookups as lookupGames } from 'lookups/games';
import useKioskApiResponse from 'hooks/useKioskApiResponse';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'gameIds';
const table = createColumnHelper();

export default function CompanyBanks() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const [ aliasDialog, setAliasDialog ] = useState({
    open: false,
    title: '',
    data: []
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const canUpdate = ability.can('update', _RNAME);
  const canDelete = ability.can('delete', _RNAME);
  const kioskApiResponse = useKioskApiResponse('game-ids');

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  useEffect(() => {
    if (!kioskApiResponse) return;

    const err = kioskApiResponse?.error;

    if (err) {
      setGlobalErrorMessage({ err });
      return;
    }
    setGlobalMessage({
      message: t(`Executed`),
      severity: 'success'
    });
  }, [kioskApiResponse, setGlobalMessage, setGlobalErrorMessage, t]);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { username, companyId } = row;
          return { username, companyId };
        }, {
          id: 'username',
          cell: info => {
            const { username, companyId } = info.getValue();
            return (<UsernameMenu username={username} companyId={companyId} />);
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor('gameType', {
          id: 'gameType',
          cell: info => {
            const val = info.getValue();
            const looked = get(lookupGames, val, '');
            return looked;
          },
          header: () => t('Game Type'),
          footer: props => props.column.id,
        }),
        table.accessor('gameId', {
          id: 'gameId',
          cell: info => info.getValue(),
          header: () => t('Game Id'),
          footer: props => props.column.id,
        }),
        table.accessor('gamePassword', {
          id: 'gamePassword',
          cell: info => info.getValue(),
          header: () => t('Game Password'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('lastHolders', {
          id: 'lastHolders',
          cell: info => {
            const val = info.getValue() || [];
            const arrLength = val.length;
            const fontWeight = val.length > 1 ? 700 : 300;
            return (
              <Button sx={{ fontWeight }} onClick={() => {
                setAliasDialog({
                  open: true,
                  title: t('Last Holders'),
                  data: val
                })
              }} color='info' variant='text'>{arrLength}
              </Button>
            );
          },
          header: () => t('Last Holders'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => {
          const balanceRaw = get(row, `balance.$numberDecimal`);
          if (!balanceRaw) return '';
          const balance = new Decimal(balanceRaw).toFixed(2);
          return balance;
        }, {
          id: 'balance',
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right'
              }}
              element='span'>
              {val}
            </Box>
          },
          header: () => t('Balance'),
          footer: props => props.column.id,
          enableSorting: true,
        }),
        table.accessor('balanceUpdatedAt', {
          cell: info => {
            const val = info.getValue();
            if (!val || !dayjs(val).isValid()) return '';
            return dayjs(val).locale(lang).format('YYYY-MM-DD HH:mm:ss')
          },
          header: () => t('Balance Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('passwordUpdatedAt', {
          cell: info => {
            const val = info.getValue();
            if (!val || !dayjs(val).isValid()) return '';
            return dayjs(val).locale(lang).format('YYYY-MM-DD HH:mm:ss')
          },
          header: () => t('Password Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('releasedAt', {
          cell: info => {
            const val = info.getValue();
            if (!val || !dayjs(val).isValid()) return '';
            return dayjs(val).locale(lang).format('YYYY-MM-DD HH:mm:ss')
          },
          header: () => t('Released At'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const handleBalanceRefresh = (rowData) => async (event) => {
    try {
      event.preventDefault();

      const { _id: rowId } = rowData;
      await feathers.service(serviceName).patch(rowId, {
        lastBalanceCheckRequestAt: new Date()
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handlePasswordReset = (rowData) => async (event) => {
    try {
      event.preventDefault();

      const { _id: rowId } = rowData;
      await feathers.service(serviceName).patch(rowId, {
        lastPasswordResetRequestAt: new Date()
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  const handleAliasDialogClose = useCallback(
    () => {
      setAliasDialog(prev => ({
        ...prev,
        open: false
      }));
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t("Delete")}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t("Refresh Balance")}>
          <span>
            <IconButton onClick={handleBalanceRefresh(data)} color='primary'>
              <RefreshIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t("Reset Password")}>
          <span>
            <IconButton onClick={handlePasswordReset(data)} color='primary'>
              <ResetIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t("Edit")}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Game Id')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <AliasDialog { ...aliasDialog } onClose={handleAliasDialogClose} />
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      <Table
        name={t('Game Ids')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={canCreate}
        onCreateClick={handleOnCreateClick}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          lastHolders: false,
          releasedAt: false,
          createdAt: false,
          updatedAt: false
        }}
      />
    </Box>
  );
}
