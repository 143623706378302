import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useMemo, useContext } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import BankContext from 'features/context/bankContext';
import CommonContext from 'features/context/commonContext';
import Decimal from 'decimal.js';
import CompanyBankMenuItemBox from 'features/companyBanks/CompanyBankMenuItemBox';
import dayjs from 'dayjs';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

import {
  FormikDateTimePicker,
  FormikSelect,
  FormikSwitch,
  FormikTextField,
  FormikUsername,
} from 'features/formikControl';

const CASL_SUBJECT = 'deposits';
const SERVICE_NAME = 'deposits';

function CreationForm(props) {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const { banks, banksReady, bankData, bankDataReady } = useContext(BankContext);
  const { companySettings, companySettingsReady } = useContext(CommonContext);
  const isDataReady = useMemo(() => banksReady && bankDataReady && companySettingsReady, [banksReady, bankDataReady, companySettingsReady]);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const minDeposit = useMemo(() => {
    const minDepositDecimal = new Decimal(companySettings[0]?.transaction?.minDeposit?.$numberDecimal || 0);
    return minDepositDecimal.toNumber();
  }, [companySettings]);

  const availableBanks = useMemo(() => {
    const result = banks.map((bank) => {
      const bankDataItem = bankData.find((item) => item.accountNumber === bank.accountNumber);
      return {
        ...bank,
        balance: bankDataItem?.balance?.$numberDecimal || 0,
      };
    });

    const filteredResult = result.filter((bank) => {
      const { isHidden = false, purposes = [] } = bank;
      return !isHidden && purposes.includes('deposit');
    });

    return filteredResult;
  }, [banks, bankData]);

  const inputSchema = Yup.object().shape({
    username: Yup.string().required(t('Required')),
    amount: Yup.number().required('Required').min(minDeposit, `>= ${minDeposit}`).max(30000, '< 30000'),
    companyBankId: Yup.string().required(t('Required')),
    transactedAt: Yup.date().required(t('Required')),
    skipReward: Yup.boolean().optional(),
    remark: Yup.string(),
    state: Yup.string().required(t('Required')).oneOf(['check', 'initial']),
  });

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      {
        !isDataReady && <LinearProgress />
      }
      <DialogTitle>{t('Create Deposit')}</DialogTitle>
      <Formik
        initialValues={{
          username: '',
          amount: '',
          companyBankId: '',
          transactedAt: new Date(),
          skipReward: false,
          remark: `Manual deposit at ${dayjs().format('YYYY-MM-DD HH:mm:ssZ')}\n`,
          state: 'check',
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={inputSchema}
        onSubmit={async (values) => {
          try {
            await feathers.service(SERVICE_NAME).create(values);
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikUsername
                      fullWidth
                      id='username'
                      name='username'
                      label={t('Username')}
                      value={values.username}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='amount'
                      name='amount'
                      label={t('Amount')}
                      value={values.amount}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      id='companyBankId'
                      name='companyBankId'
                      label={t('Company Bank')}
                      value={values?.companyBankId || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        availableBanks.map((bank) => {
                          const { _id, name, displayNumber, balance } = bank;
                          return (
                            <MenuItem key={_id} value={_id}>
                              <CompanyBankMenuItemBox name={name} displayNumber={displayNumber} balance={balance} />
                            </MenuItem>
                          );
                        })
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDateTimePicker
                      fullWidth
                      id='transactedAt'
                      name='transactedAt'
                      label={t('Transacted At')}
                      value={values?.transactedAt || new Date()}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSwitch
                      id='skipReward'
                      name='skipReward'
                      label={t('Skip Reward')}
                      value={values?.skipReward || false}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='remark'
                      multiline
                      rows={2}
                      name='remark'
                      label={t('Remark')}
                      value={values?.remark || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      id='state'
                      name='state'
                      label={t('State')}
                      value={values?.state || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value='check'>{t('Approval Required')}</MenuItem>
                      <MenuItem value='initial'>{t('Pre-Approved')}</MenuItem>
                    </FormikSelect>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel}>{t('Cancel')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Submit')} onClick={handleSubmit}>{t('Submit')}</LoadingButton>
              </DialogActions>
            </>)
        }
      </Formik>
    </Dialog>
  );
}

export default CreationForm;