import React from 'react';

export const UserDialogContext = React.createContext({
  open: false,
  onClose: () => {},
  username: null,
  companyId: null,
  onOpen: () => {},
});

export const UserDialogProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState(null);
  const [companyId, setCompanyId] = React.useState(null);

  const handleClose = React.useCallback(() => {
    setUsername(null);
    setCompanyId(null);
    setOpen(false);
  }, []);

  const handleOpen = React.useCallback((username, companyId) => {
    setUsername(username);
    setCompanyId(companyId);
    setOpen(true);
  }, []);

  const value = {
    open,
    onClose: handleClose,
    username,
    companyId,
    onOpen: handleOpen,
  };

  return (
    <UserDialogContext.Provider value={value}>
      {children}
    </UserDialogContext.Provider>
  );
};
