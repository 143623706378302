import React, { useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import ObjectDialog from 'features/objectDialog/ObjectDialog';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';
import TextSearchLink from 'features/textSearchLink/TextSearchLink';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'logonLogs';
const table = createColumnHelper();

export default function Games() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canDelete = ability.can('delete', _RNAME);
  const [ objectDialog, setObjectDialog ] = useState({
    open: false,
    title: '',
    data: null
  });

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const handleObjectDialogClose = useCallback(
    () => {
      setObjectDialog(prev => ({
        ...prev,
        open: false
      }));
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { username, companyId } = row;
          return { username, companyId };
        }, {
          id: 'username',
          cell: info => {
            const { username, companyId } = info.getValue();
            return (<UsernameMenu username={username} companyId={companyId} />);
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor('ip', {
          id: 'ip',
          cell: info => {
            const ipAddress = info.getValue() || '';
            return <TextSearchLink text={ipAddress} />;
          },
          header: () => t('IP Address'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, 'ipLocation'), {
          id: 'ipLocation.country',
          cell: info => {
            const val = info.getValue();
            if (!val) return null;

            const country = get(val, 'country', '');
            const state = get(val, 'state', '');
            const city = get(val, 'city', '');

            return (
              <>
                <Box sx={{
                  textDecoration: 'underline',
                  textDecorationColor: theme => (theme.palette.info.main),
                  textDecorationThickness: 2,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setObjectDialog({
                    open: true,
                    title: t('IP Location'),
                    data: val
                  })
                }}
                component='span'>
                  {`${country}`}
                </Box>
                <Box component='span'>
                  {`, ${state}, ${city}`}
                </Box>
              </>
            );
          },
          header: () => t('Country'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, 'deviceInfo.fingerprint', ''), {
          id: 'fingerprint',
          cell: info => info.getValue(),
          header: () => t('Fingerprint'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => get(row, 'deviceInfo'), {
          id: 'deviceInfo.deviceType',
          cell: info => {
            const val = info.getValue();
            if (!val) return null;

            const deviceType = get(val, 'deviceType', '');

            return (
              <Box sx={{
                textDecoration: 'underline',
                textDecorationColor: theme => (theme.palette.info.main),
                textDecorationThickness: 2,
                cursor: 'pointer',
              }}
              onClick={() => {
                setObjectDialog({
                  open: true,
                  title: t('Device Info'),
                  data: val
                })
              }}
              component='span'>
                {deviceType}
              </Box>
            );
          },
          header: () => t('Device Type'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('isSucceed', {
          id: 'isSucceed',
          cell: info => {
            const val = info.getValue();
            const color = val === false ? 'error.main' : 'inherit';
            const fontWeight = val === false ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('Is Succeed'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Logon Log')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <ObjectDialog { ...objectDialog } onClose={handleObjectDialogClose} />
      <Filter open={openFilter} setOpen={setOpenFilter} />
      <Table
        name={t('Logon Logs')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={false}
        onCreateClick={null}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
        }}
      />
    </Box>
  );
}
