import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import RefreshIcon from '@mui/icons-material/RefreshTwoTone';
import OpenIcon from '@mui/icons-material/PlayCircleFilledWhiteTwoTone';
import { get, kebabCase, capitalize } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import GameDialog from 'features/gameDialog/GameDialog';
import Tooltip from '@mui/material/Tooltip';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';
import { lookups as lookupGames } from 'lookups/games';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
import Decimal from 'decimal.js';
import { subject } from '@casl/ability';
dayjs.extend(relativeTime);

const _RNAME = 'gameLogs';
const table = createColumnHelper();

export default function UserRelations() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null,
    type: null,
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const [ gameDialog, setGameDialog ] = useState({
    open: false,
    data: null,
    gameType: null,
  });

  const handleGameDialogClose = useCallback(
    () => {
      setGameDialog(prev => ({
        ...prev,
        open: false
      }));
    }, []
  );

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const openConfirmDialog = useMemo(
    () => {
      const { open = false, rowId = null } = confirmDialog;
      return Boolean(open && rowId);
    }, [confirmDialog]
  );

  const setOpenConfirmDialog = useCallback(
    (open, type) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null, type: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.display({
          id: 'advancedActions',
          header: () => t('Advanced Actions'),
          cell: props => <RowAdvancedActions row={props.row} />
        }),
        table.accessor(row => {
          const { username, companyId } = row;
          return { username, companyId };
        }, {
          id: 'username',
          cell: info => {
            const { username, companyId } = info.getValue();
            return (<UsernameMenu username={username} companyId={companyId} />);
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const t = get(row, 'gameType');
          const lookup = get(lookupGames, t, '');
          return lookup;
        }, {
          id: 'gameType',
          cell: info => info.getValue(),
          header: () => t('Game Type'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `gameId`, ''), {
          id: 'gameId',
          cell: info => info.getValue(),
          header: () => t('Game Id'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, 'balanceLogs', []), {
          id: 'balanceLogs',
          cell: info => {
            const val = info.getValue();

            if (!val?.length) {
              return (
                <Box sx={{ color: 'text.disabled', fontStyle: 'oblique' }} element='span'>
                  {t('TBD')}
                </Box>
              );
            }

            const lastBalance = val[val.length - 1];
            const decVal = new Decimal(lastBalance);
            return (
              <Box element='span'>
                {decVal.toFixed(2)}
              </Box>
            );
          },
          header: () => t('Probed Balance'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => get(row, `playedGames`, []), {
          id: 'playedGames',
          cell: info => {
            const rawVal = info.getValue();
            const val = rawVal?.length ? rawVal : ['none'];
            const isNone = !val?.length || val[0] === 'none';

            return <Box
              sx={{
                color: isNone ? 'text.disabled' : 'inherit',
                fontStyle: isNone ? 'oblique' : 'inherit',
              }}
              element='span'
            >
              {val.map(i => (t(`gameCategories.${i}`))).join(', ')}
            </Box>
          },
          header: () => t('Played Games'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => {
          const dpAmount = new Decimal(get(row, `dpAmount.$numberDecimal`, '0')).toFixed(2);
          return dpAmount;
        }, {
          id: 'dpAmount',
          header: () => t('Deposit Amount'),
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right',
              }}
              element='span'>
              {val}
            </Box>
          },
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const wdAmount = new Decimal(get(row, `wdAmount.$numberDecimal`, '0')).toFixed(2);
          return wdAmount;
        }, {
          id: 'wdAmount',
          header: () => t('Withdrawal Amount'),
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right',
              }}
              element='span'>
              {val}
            </Box>
          },
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const betAmount = new Decimal(get(row, `betAmount.$numberDecimal`, '0')).toFixed(2);
          return betAmount;
        }, {
          id: 'betAmount',
          header: () => t('Turnover Amount'),
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right',
              }}
              element='span'>
              {val}
            </Box>
          },
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const totalWinLose = new Decimal(get(row, `totalWinLose.$numberDecimal`, '0'));
          return totalWinLose;
        }, {
          id: 'totalWinLose',
          header: () => t('Total W/L'),
          cell: info => {
            const val = info.getValue();
            const isWin = val.lte(0) ? true : false;
            return <Box
              sx={{
                textAlign: 'right',
                fontWeight: isWin ? '300' : '700',
                color: isWin ? 'inherit' : 'error.main'
              }}
              element='span'>
              {val.toFixed(2)}
            </Box>
          },
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const avgBet = new Decimal(get(row, `avgBet.$numberDecimal`, '0')).toFixed(2);
          return avgBet;
        }, {
          id: 'avgBet',
          header: () => t('Average Bet'),
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right',
              }}
              element='span'>
              {val}
            </Box>
          },
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const maxBet = new Decimal(get(row, `maxBet.$numberDecimal`, '0')).toFixed(2);
          return maxBet;
        }, {
          id: 'maxBet',
          header: () => t('Max Bet'),
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right',
              }}
              element='span'>
              {val}
            </Box>
          },
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const minBet = new Decimal(get(row, `minBet.$numberDecimal`, '0')).toFixed(2);
          return minBet;
        }, {
          id: 'minBet',
          header: () => t('Min Bet'),
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right',
              }}
              element='span'>
              {val}
            </Box>
          },
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { accuracy, accuracyAccepted } = row;

          return {
            accuracy,
            accuracyAccepted,
          };
        }, {
          id: 'accuracy',
          header: () => t('Accuracy'),
          cell: info => {
            const val = info.getValue();
            const { accuracy, accuracyAccepted } = val;

            if (accuracy === undefined) {
              return (
                <Box sx={{ color: 'text.disabled', fontStyle: 'oblique' }} element='span'>
                  {t('TBD')}
                </Box>
              );
            }

            const decAccuracy = new Decimal(accuracy?.$numberDecimal || '0');
            const isAcceptable = accuracyAccepted ? true : false;

            return <Box
              sx={{
                textAlign: 'right',
                fontWeight: isAcceptable ? '700' : '300',
                color: isAcceptable ? 'success.main' : 'error.main'
              }}
              element='span'>
              {decAccuracy.toFixed(2)}
            </Box>
          },
          footer: props => props.column.id,
          enableSorting: true,
        }),
        table.accessor('state', {
          cell: info => {
            const val = info.getValue();
            const isDone = val === 'done' ? true : false;
            const isCanceled = val === 'canceled' ? true : false;
            const isCheck = val === 'check' ? true : false;
            const color = isDone ? 'success.main' : isCanceled ? 'error.main' : isCheck ? 'warning.main' : 'inherit';
            const fontWeight = (isDone || isCanceled || isCheck) ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('State'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const startDate = get(row, 'startDate');
          if (!startDate) return '-';
          return dayjs(startDate).locale(lang).format('YYYY-MM-DD HH:mm:ss');
        }, {
          id: 'startDate',
          cell: info => info.getValue(),
          header: () => t('Start Date'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const endDate = get(row, 'endDate');
          if (!endDate) return '-';
          return dayjs(endDate).locale(lang).format('YYYY-MM-DD HH:mm:ss');
        }, {
          id: 'endDate',
          cell: info => info.getValue(),
          header: () => t('End Date'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const lastDownloaded = get(row, 'lastDownloaded');
          if (!lastDownloaded) return '-';
          return dayjs(lastDownloaded).locale(lang).format('YYYY-MM-DD HH:mm:ss');
        }, {
          id: 'lastDownloaded',
          cell: info => info.getValue(),
          header: () => t('Last Downloaded'),
          footer: props => props.column.id,
        }),
        table.accessor('isDownloading', {
          id: 'isDownloading',
          cell: info => {
            const val = info.getValue();
            return <Box element='span'>{t(val)}</Box>
          },
          header: () => t('Is Downloading'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId,
      type: 'delete'
    });
  };

  const handleRowReset = useCallback(
    (rowId) => async (event) => {
      event.preventDefault();
      setConfirmDialog({
        open: true,
        rowId,
        type: 'reset'
      });
    }, []
  );

  const handleRowOpen = useCallback(
    (rowId) => async (event) => {
      event.preventDefault();
      setConfirmDialog({
        open: true,
        rowId,
        type: 'open'
      });
    }, []
  );

  const onDialogConfirmed = async () => {
    try {
      const { rowId, type } = confirmDialog;
      if (type === 'reset') {
        await feathers.service(serviceName).patch(rowId, { state: 'pre-reset' });
      } else if (type === 'close') {
        await feathers.service(serviceName).patch(rowId, { state: 'close' });
      } else if (type === 'open') {
        await feathers.service(serviceName).patch(rowId, { state: 'open' });
      } else if (type === 'delete') {
        const rowId = get(confirmDialog, 'rowId');
        await feathers.service(serviceName).remove(rowId);
      }
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  const confirmDialogTitle = useMemo(
    () => {
      const { type = null } = confirmDialog;
      if (!type) return '';
      return t(`${capitalize(type)} Game Log`);
    }, [confirmDialog, t]
  );

  const confirmDialogMessage = useMemo(
    () => {
      const { type = null } = confirmDialog;
      if (!type) return '';
      return t(`Confirm to ${type}?`);
    }, [confirmDialog, t]
  );

  const handleReadLogs = (data) => async (event) => {
    const { logs, gameType } = data;
    if (!logs.length) return;

    setGameDialog({
      open: true,
      data: logs,
      gameType,
    })
  };

  function RowActions(props) {
    const data = get(props, 'row.original');
    const { _id: rowId, logs } = data;
    const canDelete = ability.can('delete', subject(_RNAME, { ...data }));
    const canUpdate = ability.can('update', subject(_RNAME, { ...data }));

    return (
      <Stack direction='row' spacing={1}>
        {
          !!canDelete &&
          <Tooltip title={t('Delete')}>
            <span>
              <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
        <Tooltip title={t('View Game Log')}>
          <span>
            <IconButton disabled={!logs.length} onClick={handleReadLogs(data)} color='success'>
              <ZoomInIcon />
            </IconButton>
          </span>
        </Tooltip>
        {
          !!canUpdate &&
          <Tooltip title={t('Edit')}>
            <span>
              <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
      </Stack>
    );
  }

  function RowAdvancedActions(props) {
    const data = get(props, 'row.original');
    const { _id: rowId, state } = data;
    const canUpdateState = ability.can('update', _RNAME, 'state');
    if (!canUpdateState) return null;

    const canReset = (state === 'done');
    const canOpen = (state === 'post-reset');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Reset Game Log')}>
          <span>
            <IconButton disabled={!canReset} onClick={handleRowReset(rowId)} color='error'>
              <RefreshIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Open Game Log')}>
          <span>
            <IconButton disabled={!canOpen} onClick={handleRowOpen(data)} color='error'>
              <OpenIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={confirmDialogTitle}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDialogConfirmed}
      >
        {confirmDialogMessage}
      </ConfirmDialog>
      <GameDialog { ...gameDialog } onClose={handleGameDialogClose} />
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      <Table
        name={t('Game Logs')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={canCreate}
        onCreateClick={handleOnCreateClick}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          gameId: false,
          advancedActions: false,
          accuracy: false,
          isDownloading: false,
          updatedAt: false,
          createdAt: false,
          avgBet: false,
          minBet: false,
        }}
      />
    </Box>
  );
}
