import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useAuth } from 'hooks/useAuth';
import { get, unset } from 'lodash';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import feathers from 'services/feathers';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import JwtRevoke from 'features/jwtRevoke/JwtRevoke';
import TwoFactor from 'features/account/TwoFactor';
import { lookups as lookupLocales } from 'lookups/locales';
import {
  FormikTextField,
  FormikSelect,
} from 'features/formikControl';

const SUPPORTED_LOCALES = [
  'en',
  //'ms',
  'zh',
  //'th,
];

export default function Contact(props) {
  const { user, update: updateUser } = useAuth();
  const { _id: userId, name = '', lang = 'en' } = user;
  const { t } = useTranslation();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ showCurrentPassword, setShowCurrentPassword ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);

  const userSchema = Yup.object().shape({
    name: Yup.string(),
    currentPassword: Yup.string().when(
      "password",
      (password, schema) => password ? schema.required(t("Required")) : schema.nullable()
    ),
    password: Yup.string().nullable(),
    lang: Yup.string().required(t("Required")).matches(new RegExp(`^(${SUPPORTED_LOCALES.join('|')})$`)),
  });

  const handleClickShowCurrentPassword = (event) => {
    event.preventDefault();
    setShowCurrentPassword(prev => !prev);
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(prev => !prev);
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 'sm' }}>
      <Paper elevation={2} sx={{ mb: 2, p: 2 }}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            _id: userId,
            name: name,
            currentPassword: '',
            password: '',
            lang: lang,
            caslSubject: 'users',
            serviceName: 'users'
          }}
          validationSchema={userSchema}
          onSubmit={async (values, { setFieldValue }) => {
            try {
              const formattedValues = { ...values };

              if (get(values, 'password') === '') unset(formattedValues, 'password');

              const savedUser = await feathers.service('users').patch(userId, formattedValues);
              updateUser(savedUser);
              setGlobalMessage({
                message: t(`Saved`),
                severity: 'success'
              });
            } catch (err) {
              setGlobalErrorMessage({ err });
            } finally {
              setFieldValue('password', '');
            }
          }}
        >
          {
            ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <>
                <Typography variant='h6' gutterBottom>
                  {
                    t('My Account')
                  }
                </Typography>
                <FormikTextField
                  margin="normal"
                  fullWidth
                  id="name"
                  name="name"
                  label={t('Name')}
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormikTextField
                  margin="normal"
                  fullWidth
                  type={showCurrentPassword ? 'text' : 'password'}
                  id='currentPassword'
                  name='currentPassword'
                  label={t('Current Password')}
                  value={get(values, 'currentPassword', '')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleClickShowCurrentPassword}
                          edge='end'
                        >
                          {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
                <FormikTextField
                  margin="normal"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  id='password'
                  name='password'
                  label={t('New Password')}
                  value={get(values, 'password', '')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleClickShowPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
                <FormikSelect
                  margin="normal"
                  fullWidth
                  id="lang"
                  name="lang"
                  label={t('Language')}
                  value={values.lang}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                {
                  SUPPORTED_LOCALES.map((locale) => (
                    <MenuItem key={locale} value={locale}>
                      {lookupLocales[locale] || locale}
                    </MenuItem>
                  ))
                }
              </FormikSelect>
              <Box sx={{ mt: 1, textAlign: 'right' }}>
                <LoadingButton color="primary" variant="contained" type="submit" loading={isSubmitting} onClick={handleSubmit}>
                  {t('Save')}
                </LoadingButton>
              </Box>
            </>)
          }
        </Formik>
      </Paper>
      <Paper elevation={2}>
        <TwoFactor />
      </Paper>
      <Paper elevation={2}>
        <JwtRevoke />
      </Paper>
    </Box>
  );
}
