import React, { useContext, useMemo, useState, useCallback } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { AbilityContext } from 'casl/Can';
import Decimal from 'decimal.js';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelIcon from '@mui/icons-material/CancelTwoTone';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';
import { lookups as lookupGames } from 'lookups/games';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'balanceTransfers';
const table = createColumnHelper();

export default function BalanceTransfers() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const [ confirmDialog, setConfirmDialog ] = useState({
    title: '',
    open: false,
    rowId: null,
    state: null,
  });
  //const canCreate = ability.can('create', _RNAME);

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

/*  useEffect(() => {
    dispatch(setFilter(_RNAME, {
      state: 'manual',
      isManualCheck: true
    }));
  }, [dispatch]);
*/
  const handleRowApprove = (rowId) => async (event) => {
    event.preventDefault();
    setConfirmDialog({
      title: t('Approval Confirmation'),
      open: true,
      rowId,
      state: 'initial'
    });
  };

  const handleRowReject = (rowId) => async (event) => {
    event.preventDefault();
    setConfirmDialog({
      title: t('Rejection Confirmation'),
      open: true,
      rowId,
      state: 'canceling'
    });
  };

  const handleConfirmed =  async () => {
    try {
      const state = get(confirmDialog, 'state');
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).patch(rowId, { state });
      setGlobalMessage({
        message: t('Saved'),
        severity: 'success'
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ title: '', open: false, rowId: null, state: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');
    const state = get(data, 'state');
    const canVerify = ability.can('update', _RNAME, 'state');
    const isManual = state === 'manual' ? true : false;
    const lastModifiedOneMinuteAgo = dayjs(get(data, 'lastModified')).isBefore(dayjs().subtract(1, 'minute'));
    const canCancel = lastModifiedOneMinuteAgo && ['transfering', 'manual'].includes(state);

    //if (!canVerify || !isManual) return null;

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Approve')}>
          <span>
            <IconButton disabled={!canVerify || !isManual} onClick={handleRowApprove(rowId)} color='success'>
              <DoneIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Reject')}>
          <span>
            <IconButton disabled={!canVerify || !canCancel} onClick={handleRowReject(data)} color='error'>
              <CancelIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { username, companyId } = row;
          return { username, companyId };
        }, {
          id: 'username',
          cell: info => {
            const { username, companyId } = info.getValue();
            return (<UsernameMenu username={username} companyId={companyId} />);
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const t = get(row, 'gameType');
          const lookup = get(lookupGames, t, '');
          return lookup;
        }, {
          id: 'gameType',
          cell: info => info.getValue(),
          header: () => t('Game Type'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `gameId`, ''), {
          id: 'gameId',
          cell: info => info.getValue(),
          header: () => t('Game Id'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const amount = new Decimal(get(row, `amount.$numberDecimal`, '0')).toFixed(2);
          const type = get(row, 'type');
          return { amount, type };
        }, {
          id: 'amount',
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right',
                fontWeight: val.type === 'deposit' ? '300' : '700',
                color: val.type === 'deposit' ? 'inherit' : 'error.main'
              }}
              element='span'>
              {val.amount}
            </Box>
          },
          header: () => t('Amount'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const type = get(row, 'type');
          const isAuto = get(row, 'isAuto', false);
          return { type, isAuto };
        }, {
          id: 'type',
          cell: info => {
            const { type, isAuto } = info.getValue();
            return <Box element='span'>
              {t(type)}
              {
                !!isAuto &&
                <Typography sx={{ color: 'info.main', ml: 1 }} variant='subtitle2' component='span'>
                  {'*'}
                </Typography>
              }
            </Box>
          },
          header: () => t('Type'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const gameBalance = new Decimal(get(row, 'gameBalance.$numberDecimal', '0')).toFixed(2);
          return gameBalance;
        }, {
          id: 'gameBalance',
          cell: info => info.getValue(),
          header: () => t('Game Balance'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const productStartBalance = new Decimal(get(row, 'productStartBalance.$numberDecimal', '0')).toFixed(2);
          return productStartBalance;
        }, {
          id: 'productStartBalance',
          cell: info => info.getValue(),
          header: () => t('Start Balance'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const productEndBalance = new Decimal(get(row, 'productEndBalance.$numberDecimal', '0')).toFixed(2);
          return productEndBalance;
        }, {
          id: 'productEndBalance',
          cell: info => info.getValue(),
          header: () => t('End Balance'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const productBalanceDiscrepancy = new Decimal(get(row, 'productBalanceDiscrepancy.$numberDecimal', '0')).toFixed(2);
          return productBalanceDiscrepancy;
        }, {
          id: 'productBalanceDiscrepancy',
          cell: info => {
            const val = info.getValue();
            const decVal = new Decimal(val);
            if (decVal.equals(0)) {
              return <Box sx={{ color: 'success.main', fontWeight: 700 }} element='span'>{`${val}`}</Box>
            } else if (decVal.gt(0)) {
              return <Box sx={{ color: 'warning.main', fontWeight: 700 }} element='span'>{`+${val}`}</Box>
            } else {
              return <Box sx={{ color: 'warning.main', fontWeight: 700 }} element='span'>{`${val}`}</Box>
            }
          },
          header: () => t('Discrepancy'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('remark', {
          id: 'remark',
          cell: info => info.getValue(),
          header: () => t('Remark'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const state = get(row, 'state');
          const isManualCheck = get(row, 'isManualCheck', false);
          return {
            state, isManualCheck
          };
        }, {
          id: 'state',
          cell: info => {
            const val = info.getValue();
            const state = get(val, 'state');
            const isManualCheck = get(val, 'isManualCheck', false);
            const isDone = state === 'done' ? true : false;
            const isCanceled = state === 'canceled' ? true : false;
            const isManual = state === 'manual' ? true : false;
            const color =
              isDone ? 'success.main' :
                isCanceled ? 'error.main' :
                  isManual ? 'info.main' :
                    'inherit';
            const fontWeight = (isDone || isCanceled || isManual) ? 700 : 'inherit';
            const textDecoration = isManualCheck ? 'underline dotted' : 'none';

            return <Box sx={{ color, fontWeight, textDecoration }} element='span'>{t(state)}</Box>
          },
          header: () => t('State'),
          footer: props => props.column.id,
        }),
        table.accessor('executedBy', {
          id: 'executedBy',
          cell: info => info.getValue(),
          header: () => t('Executer'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  return (
    <Box>
      <ConfirmDialog
        title={get(confirmDialog, 'title', 'Confirmation')}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={handleConfirmed}
      >
        {t('Are you sure?')}
      </ConfirmDialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      <Table
        name={t('Balance Transfers')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={false}
        onCreateClick={null}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          remark: false,
          executedBy: false,
          updatedAt: false,
        }}
      />
    </Box>
  );
}
