import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase, startsWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AbilityContext } from 'casl/Can';
import Switch from '@mui/material/Switch';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getCountryOptions } from 'utils/country';
import { getBankOptions, getBankLabel, checkIsBankIdType  } from 'utils/bank';
import CommonContext from 'features/context/commonContext';
import { transformSavedData } from 'utils/form-utils';
import { customAlphabet } from 'nanoid';
import { bankPurposesLookup, bankPurposeEnums } from 'lookups/banks';

const _NEWID = '@NewCompanyBank';
const _RNAME = 'companyBanks';
const _RNAME_DATA = 'companyBankData';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const superShortId = customAlphabet(alphabet, 2);

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData, userCompanyId } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const [ selectedTab, setSelectedTab ] = useState('1');
  const { bankLookup } = useContext(CommonContext);

  const hasCountryOption = useMemo(
    () => {
      return userCompanyId === '*';
    }, [userCompanyId]
  );

  const countryOptions = useMemo(
    () => {
      return getCountryOptions();
    }, []
  );

  const purposesOptions = useMemo(
    () => {
      return bankPurposeEnums.map((purpose) => {
        const translatedPurpose = t(bankPurposesLookup[purpose]);
        return (
          <MenuItem key={purpose} value={purpose}>{translatedPurpose}</MenuItem>
        );
      });
    }, [t]
  );

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        restrictions: {
          minDepositAmount: 0,
          maxDepositAmount: 0,
        },
        refPrefix: Array.from({ length: 2 }, () => superShortId()).join(','),
        remark: '',
        purposes: ['deposit', 'withdrawal'],
        isHidden: false,
        isEnabled: true
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    country: hasCountryOption ? Yup.string().required(t("Required")) : Yup.string().nullable(),
    groupName: Yup.array().of(Yup.string()).nullable(),
    name: Yup.string().required(t("Required")),
    bankName: Yup.string().required(t("Required")),
    accountName: Yup.string().required(t("Required")),
    displayNumber: Yup.string().required(t("Required")),
    accountNumber: Yup.string().required(t("Required")),
    qrCode: Yup.string().nullable(),
    refPrefix: Yup.string().optional(),
    dailyMaintenance: Yup.object().shape({
      startsAtTime: Yup.date().nullable(),
      endsAtTime: Yup.date().nullable(),
    }),
    restrictions: Yup.object().shape({
      minDepositAmount: Yup.number().min(0).integer().nullable(),
      maxDepositAmount: Yup.number().min(0).integer().nullable(),
    }),
    data: Yup.object().shape({
      dailyTxnLimit: Yup.number().min(1).integer().nullable(),
      enablesAt: Yup.date().nullable(),
    }),
    remark: Yup.string().nullable(),
    purposes: Yup.array().of(Yup.string().oneOf(bankPurposeEnums)).required(t("Required")),
    isHidden: Yup.bool().required(t("Required")),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');

        if (_id === _NEWID) {
          const flattenVal = flatten(values, { safe: true });
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, values);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const selectedCountry = useMemo(
    () => {
      return get(formik, 'values.country');
    }, [formik]
  );

  const bankOptions = useMemo(
    () => {
      if (!!selectedCountry) {
        const filteredBankLookup = Object.keys(bankLookup).reduce((acc, key) => {
          if (key.startsWith(selectedCountry)) {
            acc[key] = bankLookup[key];
          }
          return acc;
        }, {});
        return getBankOptions(t, filteredBankLookup);
      }
      return getBankOptions(t, bankLookup);
    }, [t, bankLookup, selectedCountry]
  );

  const selectedBankName = useMemo(
    () => {
      return get(formik, 'values.bankName', 'None');
    }, [formik]
  );

  const isBankIdType = useMemo(
    () => {
      return checkIsBankIdType(selectedBankName);
    }, [selectedBankName]
  );

  const isAuBank = useMemo(
    () => {
      if (startsWith(selectedBankName, 'au_')) return true;
      return false;
    }, [selectedBankName]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  function disableInputForData(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME_DATA, fieldName) ? false : true;
  }

  function handleTabChange(event, newValue) {
    event.preventDefault();
    setSelectedTab(newValue);
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Bank')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <TabContext value={selectedTab}>
            <TabList onChange={handleTabChange} scrollButtons="auto" variant="scrollable">
              <Tab label={t('General')} value="1" />
              <Tab label={t('Restrictions')} value="2" />
              <Tab label={t('Extra')} value="3" />
              <Tab label={t('Daily Maintenance')} value="4" />
            </TabList>
            <TabPanel value="1">
              <Grid container spacing={2}>
                {
                  hasCountryOption &&
                  <>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={get(formik, 'touched.country', false) && Boolean(get(formik, 'errors.country'))}
                      >
                        <InputLabel id='country-select-label'>{t('Country')}</InputLabel>
                        <Select
                          autoWidth
                          disabled={disableInput('country')}
                          labelId='country-select-label'
                          id='country'
                          name='country'
                          value={get(formik, 'values.country', '')}
                          label={t('Country')}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                          {
                            countryOptions
                          }
                        </Select>
                        <FormHelperText>{get(formik, 'touched.country', false) && get(formik, 'errors.country')}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={3}
                        fullWidth
                        disabled={disableInput('groupName')}
                        id='groupName'
                        name='groupName'
                        label={t('Group Name')}
                        value={get(formik, 'values.groupName', [])}
                        onBlur={formik.handleBlur}
                        onChange={(event) => {
                          const value = event.target.value;
                          const valuesArray = value === '' ? [] : value
                                .split('\n')
                                .map((val) => val.trim());
                          formik.setFieldValue('groupName', valuesArray);
                        }}
                        error={get(formik, `touched.groupName`, false) && Boolean(get(formik, `errors.groupName`))}
                        helperText={get(formik, `touched.groupName`, false) && get(formik, `errors.groupName`)}
                      />
                    </Grid>
                  </>
                }
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput('name')}
                    id='name'
                    name='name'
                    label={t('Name')}
                    value={get(formik, 'values.name', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.name`, false) && Boolean(get(formik, `errors.name`))}
                    helperText={get(formik, `touched.name`, false) && get(formik, `errors.name`)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={get(formik, 'touched.bankName', false) && Boolean(get(formik, 'errors.bankName'))}
                  >
                    <InputLabel id='bank-name-select-label'>{t('Bank Name')}</InputLabel>
                    <Select
                      autoWidth
                      disabled={disableInput('bankName')}
                      labelId='bank-name-select-label'
                      id='bankName'
                      name='bankName'
                      value={selectedBankName}
                      label={t('Bank Name')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                      {
                        bankOptions
                      }
                    </Select>
                    <FormHelperText>{get(formik, 'touched.bankName', false) && get(formik, 'errors.bankName')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={disableInput('displayNumber')}
                    id='displayNumber'
                    name='displayNumber'
                    label={isBankIdType ? getBankLabel(t, selectedBankName) : 'Display Number'}
                    value={get(formik, 'values.displayNumber', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.displayNumber`, false) && Boolean(get(formik, `errors.displayNumber`))}
                    helperText={get(formik, `touched.displayNumber`, false) && get(formik, `errors.displayNumber`)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={disableInput('accountNumber')}
                    id='accountNumber'
                    name='accountNumber'
                    label={isAuBank ? `Bsb + ${t('Account Number')}` : t('Account Number')}
                    value={get(formik, 'values.accountNumber', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.accountNumber`, false) && Boolean(get(formik, `errors.accountNumber`))}
                    helperText={get(formik, `touched.accountNumber`, false) && get(formik, `errors.accountNumber`)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput('accountName')}
                    id='accountName'
                    name='accountName'
                    label={t('Account Name')}
                    value={get(formik, 'values.accountName', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.accountName`, false) && Boolean(get(formik, `errors.accountName`))}
                    helperText={get(formik, `touched.accountName`, false) && get(formik, `errors.accountName`)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput('qrCode')}
                    id='qrCode'
                    name='qrCode'
                    label={t('QR Code')}
                    value={get(formik, 'values.qrCode', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.qrCode`, false) && Boolean(get(formik, `errors.qrCode`))}
                    helperText={get(formik, `touched.qrCode`, false) && get(formik, `errors.qrCode`)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput('refPrefix')}
                    id='refPrefix'
                    name='refPrefix'
                    label={t('Ref Prefix')}
                    value={get(formik, 'values.refPrefix', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={t('One, Two, Three')}
                    error={get(formik, `touched.refPrefix`, false) && Boolean(get(formik, `errors.refPrefix`))}
                    helperText={get(formik, `touched.refPrefix`, false) && get(formik, `errors.refPrefix`)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={get(formik, 'touched.purposes', false) && Boolean(get(formik, 'errors.purposes'))}
                  >
                    <InputLabel id='purposes-select-label'>{t('Purposes')}</InputLabel>
                    <Select
                      autoWidth
                      disabled={disableInput('purposes')}
                      labelId='purposes-select-label'
                      id='purposes'
                      name='purposes'
                      value={get(formik, 'values.purposes', [])}
                      label={t('Purposes')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      multiple
                    >
                      {
                        purposesOptions.map((option) => option)
                      }
                    </Select>
                    <FormHelperText>{get(formik, 'touched.purposes', false) && get(formik, 'errors.purposes')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch disabled={disableInput('isHidden')} checked={formik.values.isHidden} onChange={(event) => {
                        const isChecked = get(event, 'target.checked', false);
                        formik.setFieldValue('isHidden', isChecked)
                      }} />
                    }
                    label={t('Hidden')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                        const isChecked = get(event, 'target.checked', false);
                        formik.setFieldValue('isEnabled', isChecked)
                      }} />
                    }
                    label={t('Enabled')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    disabled={disableInput('remark')}
                    id='remark'
                    name='remark'
                    label={t('Remark')}
                    value={get(formik, 'values.remark', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.remark`, false) && Boolean(get(formik, `errors.remark`))}
                    helperText={get(formik, `touched.remark`, false) && get(formik, `errors.remark`)}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput('restrictions.minDepositAmount')}
                    id='restrictions.minDepositAmount'
                    name='restrictions.minDepositAmount'
                    label={t('Min Deposit Amount')}
                    value={get(formik, 'values.restrictions.minDepositAmount', '0')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.restrictions.minDepositAmount`, false) && Boolean(get(formik, `errors.restrictions.minDepositAmount`))}
                    helperText={get(formik, `touched.restrictions.minDepositAmount`, false) && get(formik, `errors.restrictions.minDepositAmount`)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput('restrictions.maxDepositAmount')}
                    id='restrictions.maxDepositAmount'
                    name='restrictions.maxDepositAmount'
                    label={t('Max Deposit Amount')}
                    value={get(formik, 'values.restrictions.maxDepositAmount', '0')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.restrictions.maxDepositAmount`, false) && Boolean(get(formik, `errors.restrictions.maxDepositAmount`))}
                    helperText={get(formik, `touched.restrictions.maxDepositAmount`, false) && get(formik, `errors.restrictions.maxDepositAmount`)}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInputForData('dailyTxnLimit')}
                    id='data.dailyTxnLimit'
                    name='data.dailyTxnLimit'
                    label={t('Daily Txn Limit')}
                    value={get(formik, 'values.data.dailyTxnLimit', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.data.dailyTxnLimit`, false) && Boolean(get(formik, `errors.data.dailyTxnLimit`))}
                    helperText={get(formik, `touched.data.dailyTxnLimit`, false) && get(formik, `errors.data.dailyTxnLimit`)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      views={['day', 'hours', 'minutes', 'seconds']}
                      inputFormat="YYYY-MM-DD HH:mm:ss"
                      renderInput={(props) => <TextField fullWidth {...props} />}
                      disabled={disableInputForData('enablesAt')}
                      id='data.enablesAt'
                      label={t('Enables At')}
                      value={get(formik, 'values.data.enablesAt', null)}
                      onBlur={formik.handleBlur}
                      onChange={(date) => {
                        if (!dayjs(date).isValid()) formik.setFieldValue('data.enablesAt', '');
                        else formik.setFieldValue('data.enablesAt', date.toDate());
                      }}
                      error={get(formik, 'touched.data.enablesAt', false) && Boolean(get(formik, 'errors.data.enablesAt'))}
                      helperText={get(formik, 'touched.data.enablesAt', false) && get(formik, 'errors.data.enablesAt')}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DateTimePicker
                      views={['hours', 'minutes', 'seconds']}
                      inputFormat="HH:mm:ss"
                      renderInput={(props) => <TextField fullWidth {...props} />}
                      disabled={disableInputForData('dailyMaintenance.startsAtTime')}
                      id='dailyMaintenance.startsAtTime'
                      label={t('Starts At Time')}
                      value={get(formik, 'values.dailyMaintenance.startsAtTime', null)}
                      onBlur={formik.handleBlur}
                      onChange={(date) => {
                        if (!dayjs(date).isValid()) formik.setFieldValue('dailyMaintenance.startsAtTime', '');
                        else formik.setFieldValue('dailyMaintenance.startsAtTime', date.toDate());
                      }}
                      error={get(formik, 'touched.dailyMaintenance.startsAtTime', false) && Boolean(get(formik, 'errors.dailyMaintenance.startsAtTime'))}
                      helperText={get(formik, 'touched.dailyMaintenance.startsAtTime', false) && get(formik, 'errors.dailyMaintenance.startsAtTime')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DateTimePicker
                      views={['hours', 'minutes', 'seconds']}
                      inputFormat="HH:mm:ss"
                      renderInput={(props) => <TextField fullWidth {...props} />}
                      disabled={disableInputForData('dailyMaintenance.endsAtTime')}
                      id='dailyMaintenance.endsAtTime'
                      label={t('Ends At Time')}
                      value={get(formik, 'values.dailyMaintenance.endsAtTime', null)}
                      onBlur={formik.handleBlur}
                      onChange={(date) => {
                        if (!dayjs(date).isValid()) formik.setFieldValue('dailyMaintenance.endsAtTime', '');
                        else formik.setFieldValue('dailyMaintenance.endsAtTime', date.toDate());
                      }}
                      error={get(formik, 'touched.dailyMaintenance.endsAtTime', false) && Boolean(get(formik, 'errors.dailyMaintenance.endsAtTime'))}
                      helperText={get(formik, 'touched.dailyMaintenance.endsAtTime', false) && get(formik, 'errors.dailyMaintenance.endsAtTime')}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </TabPanel>
          </TabContext>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
