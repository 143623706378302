import { useCallback, useEffect, useState } from 'react';
import feathers from 'services/feathers';
import { get } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

export default function useFeathersGet(serviceName, idToGet) {
  const service = feathers.service(`/${serviceName}`);
  const [ fetchedData, setFectchedData ] = useState(null);
  const [ status, setStatus ] = useState('fetch');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const onChangedCallback = useCallback(
    (data) => {
      const _id = get(data, '_id');

      if (idToGet !== _id) return;

      setFectchedData((prevState) => {
        return {
          ...prevState,
          data,
        }
      });
    },
    [idToGet]
  );

  const onDeletedCallback = useCallback(
    (data) => {
      const _id = get(data, '_id');

      if (idToGet !== _id) return;

      setFectchedData(null);
    },
    [idToGet]
  );

  useEffect(() => {
    if (!idToGet) return;

    setFectchedData(null);
    setStatus('fetch');
  }, [idToGet]);

  useEffect(() => {
    let isMounted = true;

    if (status !== 'fetch') return;

    if (!idToGet) {
      setStatus('idle');
      return;
    }

    async function fetch () {
      try {
        const find = await service.get(idToGet);
        if (isMounted) {
          setFectchedData(find);
        }
      } catch (err) {
        if (isMounted) {
          setGlobalErrorMessage({ err });
        }
      }
      if (isMounted) setStatus('idle');
    };

    fetch();
    return () => {
      isMounted = false;
    };
  }, [idToGet, status, service, setGlobalErrorMessage]);

  useEffect(() => {
    if (status !== 'idle') return;

    service.on('removed', onDeletedCallback);
    service.on('updated', onChangedCallback);
    service.on('patched', onChangedCallback);

    return () => {
      service.removeListener('removed', onDeletedCallback);
      service.removeListener('updated', onChangedCallback);
      service.removeListener('patched', onChangedCallback);
    };
  }, [service, status, fetchedData, onChangedCallback, onDeletedCallback]);

  return {
    data: fetchedData,
    ready: status === 'idle',
  };
}
