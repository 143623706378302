import React, { useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import ObjectDialog from 'features/objectDialog/ObjectDialog';
import AliasDialog from 'features/aliasDialog/AliasDialog';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';
import TextSearchLink from 'features/textSearchLink/TextSearchLink';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'deviceFingerprints';
const table = createColumnHelper();

export default function Games() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canDelete = ability.can('delete', _RNAME);
  const [ objectDialog, setObjectDialog ] = useState({
    open: false,
    title: '',
    data: null
  });
  const [ aliasDialog, setAliasDialog ] = useState({
    open: false,
    title: '',
    data: []
  });

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const handleAliasDialogClose = useCallback(
    () => {
      setAliasDialog(prev => ({
        ...prev,
        open: false
      }));
    }, []
  );

  const handleObjectDialogClose = useCallback(
    () => {
      setObjectDialog(prev => ({
        ...prev,
        open: false
      }));
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, 'fingerprint', ''), {
          id: 'fingerprint',
          cell: info => info.getValue(),
          header: () => t('Fingerprint'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { usernameAlias, companyId } = row;
          return { usernameAlias, companyId };
        }, {
          id: 'usernameAlias',
          cell: info => {
            const { usernameAlias = [], companyId } = info.getValue();
            return (
              usernameAlias.map(function (username) {

                return (
                  <Box key={username} sx={{ my: 1 }}>
                    <UsernameMenu username={username} companyId={companyId} />
                    <br />
                  </Box>
                );
              })
            );
          },
          header: () => t('Username Alias'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('lastIpAddresses', {
          id: 'lastIpAddresses',
          cell: info => {
            const ips = info.getValue() || [];
            return (
              ips.map(function (ip, index) {
                return (
                  <Box key={`${index}-${ip}`} sx={{ my: 1 }}>
                    <TextSearchLink text={ip} />
                    <br />
                  </Box>
                );
              })
            );
          },
          header: () => t('Last IP Addresses'),
          footer: props => props.column.id,
        }),
        table.accessor('ipLocations', {
          id: 'ipLocations',
          cell: info => {
            const locations = info.getValue() || [];
            return (
              locations.map(function (location) {
                return <Typography key={location} variant='body2' gutterBottom>{location}</Typography>
              })
            );
          },
          header: () => t('IP Locations'),
          footer: props => props.column.id,
        }),
        table.accessor(row => row, {
          id: 'deviceType',
          cell: info => {
            const row = info.getValue();
            const {
              deviceType,
              osName,
              osVersion,
              browserName,
              fullBrowserVersion,
              mobileVendor,
              mobileModel,
            } = row;

            return (
              <Box sx={{
                textDecoration: 'underline',
                textDecorationColor: theme => (theme.palette.info.main),
                textDecorationThickness: 2,
                cursor: 'pointer',
              }}
              onClick={() => {
                setObjectDialog({
                  open: true,
                  title: t('Device Info'),
                  data: {
                    deviceType,
                    osName,
                    osVersion,
                    browserName,
                    fullBrowserVersion,
                    mobileVendor,
                    mobileModel
                  }
                })
              }}
              component='span'>
                {deviceType}
              </Box>
            );
          },
          header: () => t('Device Type'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Fingerprint')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <AliasDialog { ...aliasDialog } onClose={handleAliasDialogClose} />
      <ObjectDialog { ...objectDialog } onClose={handleObjectDialogClose} />
      <Filter open={openFilter} setOpen={setOpenFilter} />
      <Table
        defaultSort={
          [
            { id: 'updatedAt', desc: true }
          ]
        }
        name={t('Fingerprints')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={false}
        onCreateClick={null}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
        }}
      />
    </Box>
  );
}
