import React, { useCallback, createContext, useContext } from 'react';
import { get, startsWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';

const GlobalMessageActionsContext = createContext();

/* eslint-disable */
const useContextFactory = (name, context) => {
  return () => {
    const ctx = useContext(context);
    if (ctx === undefined) {
      throw new Error(`use${name}Context must be used within a ${name}ContextProvider.`)
    }
    return ctx;
  }
};

export const useGlobalMessageActionsContext = useContextFactory('GlobalMessageActions', GlobalMessageActionsContext);

/* eslint-enable */
const GlobalMessageContextProvider = (props) => {
  const { t } = useTranslation('error');
  const auth = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const setGlobalErrorMessage = useCallback(
    (options) => {
      function formatErrorMessage(err) {
        const ttl = get(err, 'data.ttl');
        const errorName = get(err, 'name');
        const errorMessage = get(err, 'message', 'Unknown error');

        if (errorName === 'NotAuthenticated') {
          auth.logout();
          return t('NotAuthenticated');
        } else if (errorName === 'TimeoutError' || errorName === 'Timeout') {
          return t('TimeoutError');
        } else if (errorName === 'Forbidden') {
          return t('Forbidden');
        } else if (errorName === 'KioskInactiveError') {
          return t('KioskInactiveError');
        } else if (ttl !== undefined) {
          return t('Slowdown', { count: ttl });
        } else if (startsWith(errorMessage, 'E11000')) {
          return t('DuplicateError');
        } else if (startsWith(errorMessage, 'Validation failed:')) {
          return errorMessage;
        } else {
          console.error(`[Error] name: ${errorName}, message: ${errorMessage}`);
          return errorMessage;
        }
      }
      const errorMessage = formatErrorMessage(options?.err);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        action: (key) => (
          <>
            <Button sx={{ color: 'white' }} size='small' onClick={() => closeSnackbar(key)}>
              {t('Dismiss')}
            </Button>
          </>
        )
      });
    }, [auth, closeSnackbar, enqueueSnackbar, t]
  );

  const setGlobalMessage = useCallback(
    (options) => {
      const { message = '', severity = 'info' } = options;
      enqueueSnackbar(message, {
        variant: severity,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        action: (key) => (
          <>
            <Button sx={{ color: 'white' }} size='small' onClick={() => closeSnackbar(key)}>
              {t('Dismiss')}
            </Button>
          </>
        )
      });
    }, [closeSnackbar, enqueueSnackbar, t]
  );

  return (
    <GlobalMessageActionsContext.Provider value={{
      setGlobalMessage,
      setGlobalErrorMessage,
    }}>
      {props.children}
    </GlobalMessageActionsContext.Provider>
  )
}

export default GlobalMessageContextProvider;
