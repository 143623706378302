import React from "react";
import propTypes from "prop-types";
import { Box } from "@mui/material";
import Decimal from 'decimal.js';

export default function CompanyBankMenuItemBox({ name, displayNumber, balance }) {
  const balanceDecimal = new Decimal(balance);

  return (
    <Box display='flex' justifyContent='space-between' sx={{ width: '100%' }} alignItems='center'>
      <Box>
        <Box>{name}</Box>
        <Box sx={{
          fontSize: '.9rem',
          color: theme => theme.palette.text.secondary,
        }}
        >{displayNumber}</Box>
      </Box>
      <Box sx={{
        fontWeight: 'bold',
        color: theme => balanceDecimal.greaterThan(0) ? theme.palette.success.main : theme.palette.error.main,
      }}>
        {balanceDecimal.toFixed(2)}
      </Box>
    </Box>
  );
}

CompanyBankMenuItemBox.propTypes = {
  name: propTypes.string.isRequired,
  displayNumber: propTypes.string.isRequired,
  balance: propTypes.string.isRequired,
};