import React, { useMemo } from 'react';
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  CssBaseline,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Grid,
} from '@mui/material';
import {
  Menu as MenuIcon,
  FavoriteTwoTone as FavoriteIcon,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function ThemePreview(prop) {
  const { data: dataString, fontImportUrls = [], gameButtonVariant = undefined } = prop;

  const isPlainGameButton = useMemo(() => {
    return gameButtonVariant === 'plain';
  }, [gameButtonVariant]);

  const data = useMemo(() => {
    try {
      const ret = JSON.parse(dataString);

      const test = testTheme(ret);

      if (!test) {
        throw new Error('Invalid theme data');
      }

      return ret;
    } catch (e) {
      return null;
    }
  }, [dataString]);

  function testTheme(data) {
    try {
      const theme = createTheme(data);

      if (!['light', 'dark'].includes(theme.palette.mode)) {
        return false;
      }

      return !!(<ThemeProvider theme={theme} />);
    } catch (e) {
      return false;
    }
  }

  const theme = useMemo(() => {
    if (!data) {
      return null;
    }

    return createTheme(data);
  }, [data]);

  if (!theme || !data) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style>
        {
          fontImportUrls.map((url) => `@import url(${url});`).join('\n')
        }
      </style>
      <Container component='main'>
        <Box component='body'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Box id='appBar-right' sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right', alignItems: 'center', height: '100%' }}>
                <Stack direction='row' spacing={1}>
                  <IconButton color='inherit' variant='contained' size='small'>
                    <FavoriteIcon />
                  </IconButton>
                  <IconButton color='inherit' variant='contained' size='small'>
                    <FavoriteIcon />
                  </IconButton>
                </Stack>
              </Box>
            </Toolbar>
          </AppBar>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
          }}>
            <Paper sx={{ p: 1 }}>
              <Typography variant="h6" component="div">
                {`Hello, ${data.palette.mode} paper`}
              </Typography>
              <Box sx={{
                p: 1,
              }}>
                <LinearProgress sx={{
                  height: 14,
                }} />
              </Box>
            </Paper>
            <Card>
              <CardHeader title="Theme Preview" />
              <CardContent>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 2,
                }}>
                  <Chip label="Primary" color="primary" size="small" />
                  <Chip label="Secondary" color="secondary" size="small" />
                  <Chip label="Error" color="error" size="small" />
                  <Chip label="Warning" color="warning" size="small" />
                  <Chip label="Info" color="info" size="small" />
                  <Chip label="Success" color="success" size="small" />
                </Box>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary">
                  Primary
                </Button>
                <Button variant="contained" color="secondary">
                  Secondary
                </Button>
              </CardActions>
            </Card>
            {
              isPlainGameButton && (
                <Card>
                  <CardHeader title="Game Button Preview" />
                  <CardContent>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 2,
                    }}>
                      <Grid container spacing={1}>
                        {
                          Array.from({ length: 6 }).map((_, index) => (
                            <Grid
                              item
                              xs={4}
                              key={index}
                              sx={{
                                height: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Box
                                className='game-button'
                                sx={{
                                  position: 'relative',
                                  textAlign: 'center',
                                  backgroundSize: 'contain',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  width: theme.spacing(12),
                                  height: theme.spacing(12),
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Box
                                  className='game-button-label'
                                  component='box'
                                  variant='box'
                                  sx={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    position: 'absolute',
                                    width: '100%',
                                    left: '50%',
                                    bottom: 0,
                                    zIndex: 19,
                                    transform: 'translate(-50%, 0%)',
                                  }}
                                >
                                  <Typography
                                    className='game-button-label-text'
                                    variant='subtitle2'
                                    sx={{
                                      fontWeight: 700,
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textTransform: 'uppercase',
                                      color: 'white',

                                      textShadow: '2px 2px 2px #000',
                                    }}
                                  >
                                    {`#${index + 1}`}
                                  </Typography>
                                </Box>
                                <Box
                                  className='game-button-image'
                                  component='img'
                                  src={`https://placehold.co/120x120/`}
                                  alt={`${index + 1} game logo`}
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    width: '50%',
                                    transform: 'translate(-50%, -55%)',
                                    zIndex: 20
                                  }}
                                />
                              </Box>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              )
            }
          </Box>
          <BottomNavigation showLabels={true}>
            <BottomNavigationAction label="1" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="2" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="3" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="4" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="5" icon={<FavoriteIcon />} />
          </BottomNavigation>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
