import { useState, useCallback, useContext } from 'react';
import { Typography, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Person as PersonIcon, Search as SearchIcon } from '@mui/icons-material';
import { deepPurple } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { UserDialogContext } from 'features/context/userDialogContext';
import { useNavigate } from "react-router-dom";

function UsernameMenu({ username, companyId }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onOpen } = useContext(UserDialogContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUsernameSearch = useCallback(
    () => {
      const cacheBuster = Date.now();
      navigate(`?textSearch=${username}&cacheBuster=${cacheBuster}`);
      handleClose();
    }, [navigate, username]
  );

  const handleUsernameView = useCallback(
    () => {
      onOpen(username, companyId);
      handleClose();
    }, [onOpen, username, companyId]
  );

  if (!username?.length) return null;

  return (
    <>
    <Typography component='span' variant='body2' gutterBottom
      onClick={handleClick}
      sx={{
        textDecoration: 'underline',
        textDecorationColor: deepPurple[500],
        textDecorationThickness: 2,
        cursor: 'pointer',
      }}
    >
      {username}
    </Typography>
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem onClick={handleUsernameSearch}>
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary={t('Search')} />
      </MenuItem>
      <MenuItem onClick={handleUsernameView}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={t('View Profile')} />
      </MenuItem>
    </Menu>
    </>
  );
}

export default UsernameMenu;
